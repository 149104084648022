<template lang="pug">
div
</template>

<script setup lang="ts">
import { Device } from "@capacitor/device";
import { localeCodes } from "~/i18n/locales";

/*
  This page exists only to redirect the use to the root for their locale should they, for any reason,
  not get redirected by the server.
  The capacitor native app does not use the server, so this redirects native users to their correct locale.
 */

const router = useRouter();
const i18n = useI18n();

onMounted(async () => {
  const langCode =
    (await Device.getLanguageCode()).value ?? i18n.fallbackLocale.value;
  const locale =
    localeCodes.find((l) => l === langCode) ?? i18n.fallbackLocale.value;
  await router.replace(`/${locale}/`);
});
</script>
